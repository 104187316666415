.svgLoader {
    animation: spin 0.5s linear infinite;
    margin: auto;
  }
  .divLoader {
    // width: 100vw;
    // height: 100vh;
    // display: flex;
    // align-items: center;
    // justify-content: center;
    z-index: 9999;
    position: fixed;
    top: calc(50% - 120px);
    left: calc(50% - 80px);
    transform: translate(-50%, -50%);
    // margin: -50px 0px 0px -50px;
    background-color: rgba( 255, 255, 255, .8 );
    border-radius: 10%;
    text-align: center;
    
  }
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

  .spinner-message{
    font-size: small;
    padding: 10px;
    color: #595959;
  }