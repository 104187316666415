@import 'variables';

.home-banner{
    background-image: url("./../assets/img/ECRAN_ANNONCE_BANNIERE.jpg")  ;
    background-size: cover;
    height: 100%;
    overflow: hidden;
    background-position: center center;
    justify-content: center;
    padding-bottom: 50px;
}
.labels-list {
    list-style-type: none;
  }
  
  .label-item {
    display: flex;
    align-items: center;
    margin-bottom: 5px; 
  }
  
  .label-item svg {
    margin-right: 5px; 
  }

.dark {
    .home-banner {
        filter: invert(100%) hue-rotate(180deg);
    }
}

.w-110 {
    width: 110% !important;
  }
  
.w-125 {
width: 125% !important;
}

.search-input-default {
    height: 44px;
    padding: 0px !important;
}

.autocomplete-field {
    margin: 0;
    width: 125%; /* Taille par défaut */
    margin-left: -15px; /* Ajustement pour le padding négatif */
}

@media (min-width: 576px) { /* Écran petit ou version mobile */
    .autocomplete-field {
      width: 115%;
      margin-left: -15px; /* Ajustement pour le padding négatif */
    }
  }
  
  @media (min-width: 992px) { /* Écran large */
    .autocomplete-field {
      width: 110%;
      margin-left: -15px; /* Ajustement pour le padding négatif */
    }
  }

.home-banner-title-div{
    place-content: center;
    text-align: center;
}

.home-banner-title{
    font-weight: bold;
    color: #ffffff;
    margin-top: 160px;
}
.opportunity-title{
    // font-weight: bold;
    text-transform: uppercase;
    font-family: "Big Shoulders Display", sans-serif;
    color: black;
    margin-top: 10px;
    margin-bottom: 20px;
    font-weight: 500;
}

.dark {
    .home-banner-title {
        filter: invert(100%) hue-rotate(180deg);
    }
}

.home-btn-style{
    border-radius:30px;
    font-weight: 600;
    padding: 12px 30px;
}

.home-banner-btns{
    justify-content: center;
    margin-top: 50px;
    margin-bottom: 60px;
    text-align: center;
    background-color: transparent;
}

.home-banner-btn-style{
    border-radius:30px;
    //font-weight: 600;
    padding: 12px 10px;
    width: 250px;    
}

.nav-options {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  
  .option {
    margin-left: 10px;
    // max-width: 150px;
    white-space: nowrap;
  }

.dark {
    .home-banner-btn-style {
        filter: invert(100%) hue-rotate(180deg);
    }
}

.home-stepper-div{
    background-color: #ffffff;
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
}

.home-stepper-title{
    font-weight: bold;
    margin-top: 40px;
}

.home-stepper-NB{
    font-size: xx-small;
    margin-top: 20px;
    margin-bottom: 30px;
    font-style: italic; 

    p {
        text-indent: -7px;
        margin-bottom: 0;
    }

    p:last-child{
        padding-left: 6px;
    }
}

.home-cooptation-opportunity{
    padding: 5px 0 25px 0;
}

.home-cooptation-opportunity-div{
    width: 88%;
    margin: auto;
    background-color: #f5f5f5;
    justify-content: center;
    .home-cooptation-opportunity-div-title{
        font-weight: bold;
        text-transform: uppercase;
        font-family: "Big Shoulders Display", sans-serif;
        text-align: left;
        //margin-top: 40px;
    
        &.with-success-bg {
            background-color: $green;
            color: #fff;
        }
    }
    h3 {
        //margin-top: 4rem;
        color: $green;
        margin-bottom: 1rem;
        font-size: 1rem;
        font-weight: 900;
    } 
    
    p.ntf {
        padding-left: 1rem;
        font-weight: 500;
        font-style: italic;
        font-size: 90%; 
        color: lightslategray;       
    }
}
.home-opportunity-div{
    width: 100%;
    margin: auto;
    background-color: #f5f5f5;
    justify-content: center;
    .home-cooptation-opportunity-div-title{
        // font-weight: bold;
        color: #02735E;
        font-family: "Big Shoulders Display", sans-serif;
        text-transform: uppercase;
        font-size: large;
        font-weight: 600;
        //margin-top: 40px;
    
        &.with-success-bg {
            background-color: $green;
            color: #fff;
        }
    }
    h3 {
        //margin-top: 4rem;
        color: $green;
        margin-bottom: 1rem;
        font-size: 1rem;
        font-weight: 900;
    } 
    
    p.ntf {
        padding-left: 1rem;
        font-weight: 500;
        font-style: italic;
        font-size: 90%; 
        color: lightslategray;       
    }
}

.floating-buttons-mobile {
    display: none; /* Pour les cacher par défaut */
  }
  
  @media (max-width: 576px) {
    .floating-buttons-mobile {
      position: fixed;
      bottom: 10px;
      left: 0;
      width: 100%;
      display: flex;
      justify-content: space-around;
      padding: 10px;
      background-color: #fff;
      box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.1);
      z-index: 999; /* Pour s'assurer que les boutons restent au-dessus du contenu */
    }
  }

// .border-opportunity {
//     border: 3px solid #02735E; 
//     padding-top: 5px; 
//     padding-right: 20px; 
//     // padding-bottom: 5px; 
//     padding-left: 20px;
//     box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
//     background-color: #FFFFFF;
//     cursor: pointer;
// }
.border-opportunity {
    padding-top: 5px; 
    padding-right: 20px; 
    padding-left: 20px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    background-color: #FFFFFF;
    cursor: pointer;
    border: 3px solid transparent;
    border-image: linear-gradient(to right, black, #490101, #0BD6BE) 1;
    border-radius: 2px;
}


.border-opportunity-search {
    background-color: white;
    margin-top: -90px;
    // padding-top: 5px; 
    // padding-right: 20px; 
    // padding-bottom: 5px; 
    // padding-left: 20px;
}

.btn-recommend {
    background-color: #02735E;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 2px;
}

// .btn-ajout {
//     background-color: white;
//     color: black;
//     border: 2px solid black;
//     padding: 10px 20px;
//     border-radius: 2px;
//     margin-top: -30px;
// }
.btn-ajout {
    background-color: white;
    color: black;
    padding: 10px 20px;
    border-radius: 2px;
    margin-top: -30px;
    border: 2px solid transparent;
    border-image: linear-gradient(to right, black, #490101, #0BD6BE) 1;
}


.link-label{
    font-weight: bold;
    white-space: nowrap; 
}

.full-width-select {
    width: 100%;
    height: 30px;
    border-color: #FFFFFF;
    border-right-color: #F4F4F4;
    margin-left: 20px;
}

.home-cooptation-opportunity-description{
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: justify;
}

.home-cooptation-opportunity-article-blog{
    //width:-webkit-fill-available;
    height: auto;
}

.home-cooptation-opportunity-article-img{
    width:-webkit-fill-available;
    height: auto;
  
}
.home-cooptation-opportunity-article-img-p{
    text-align: center;
}
.home-cooptation-opportunity-article-title{
    color: #009c49;
    font-weight: 900;
    margin-top: 30px;
    font-size: 1.12rem;
    text-transform: uppercase;
    height:70px;
}
.home-cooptation-opportunity-article-description{
    font-size: 16px;
    margin-top: 30px;
}
.home-articles{
    margin-top: 20px;
    justify-content: center;
    // margin-bottom: 100px;
    //margin-left: -1.8%;
    // left: 5%;
    // right: 5%;
}
.home-cooptation-quote-div{
    background-color: #ffffff;
    display: flex;
    flex-wrap: wrap;
}
.home-cooptation-quote-img{
    max-width: -webkit-fill-available;
    height: auto;
}
.home-cooptation-quote-div-2{
    margin-top: 100px;
    margin-bottom: 100px;
}
.home-cooptation-quote-title{
    align-self: center;
  
}

.home-cooptation-quote-title-laquo{
    margin-top: 0px;
    //color: #00c072;
    font-weight: bold;
}
.home-cooptation-quote{
    font-size: 30px;
}
.home-cooptation-quote-author{
    font-size: small;
}
.home-cooptation-end-div{
    background-color: #20d088;
}
.home-cooptation-end-div-2{
    margin-top: 100px;
    margin-bottom: 100px;
}
.home-cooptation-end-btn-style{
    background-color: #ffffff;
    border-radius:30px;
    font-weight: 600;
    font-size: large;
    padding: 12px 40px;
}

.home-cooptation-end-btn-style:hover{
    color:#20d088;
}
.home-cooptation-end-text-div{
    color: #ffffff;
    font-weight: 800;
    font-size: xx-large;
}
.home-cooptation-end-btn-div{
    text-align: center;
    align-self: center;
}

.article-div{
    background-color: #ffffff;
    //max-width: 440px;
    margin-left: 1%;
    margin-right: 1%;
    padding: 2%;
    box-shadow: 1px 1px 12px #e4e4e4;
    height: auto; 
}

/*Stepper*/
$fill-color: #199473;
$border-color: #00c072;
$text-color: #102A43;
$text-color-inactive: #2d2927;
$text-color-optional: #9FB3C8;
$text-color-number:#00c072;

$font-size-base: 15px !default;
// Breakpoints
$screen-xsm: 400px / $font-size-base + 0em;
$screen-sm: 720px / $font-size-base + 0em;
$screen-lg: 960px;

.video-container {
  position: relative;
  padding-bottom: 56.25%; /* Ratio d'aspect 16:9 (hauteur/largeur) */
  height: 0;
  overflow: hidden;

    iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    }  
}

.stepper-div {
  
        display:table;
        width:100%;
        margin:0 auto;
        margin-top: 50px;
        margin-bottom: 30px;
    
        .step {
            display:table-cell;
            position:relative;
            padding:1.5rem 0.2rem;
            z-index: 2;
            width: 25%;
            &:last-child .step-bar-left,
            &:last-child .step-bar-right {
                display:none;
            }

            .step-circle {
                width:3.5rem;
                height:3.5rem;
                margin:0 auto;
                border-radius: 50%;
                text-align: center;
                line-height:3rem;
                font-size: 2rem;
                font-weight: 700;
                z-index:2;
                border: 4px solid $border-color;
                color:$text-color-number;
            }

            &.done {
                .step-circle {
                    background-color:$fill-color;
                    border: 2px solid $fill-color;
                    color:#FFFFFF;

                    &:before {
                        font-family:'FontAwesome';
                        font-weight:100;
                        content: "\f00c";
                    }
                    * {
                        display:none;
                    }
                }
                .step-title {
                    color:$text-color;
                }
            }

            &.editing {
                .step-circle {
                    background: #ffffff;
                    border-color:$fill-color;
                    color:$fill-color;
                }
                .step-title {
                    color:$fill-color;
                    text-decoration: underline;
                }
            }

            .step-title {
                margin-top:1rem;
                font-size:1rem;
                font-weight:500;
            }
            .step-title, .step-optional {
                text-align: center;
                color: $text-color-inactive;
            }
            .step-optional {
                font-size:0.75rem;
                font-style: italic;
                color: $text-color-optional;
            }

            .step-bar-left, .step-bar-right {
                position:absolute;
                top:calc(2rem + 15px);
                height:5px;
                background-color: $border-color;
                border: solid $border-color;
                border-width: 2px 0;
            }
            .step-bar-left {
                width: calc(100% - 3.42rem);
                left:50%;
                margin-left:1.7rem;
                z-index:-1;
            }
            .step-bar-right {
                width:0;
                left:50%;
                margin-left:1rem;
                z-index:-1;
                transition: width 500ms ease-in-out;
            }

            // &.done .step-bar-left, &.editing .step-bar-left {
            //     background-color:$fill-color;
            //     border-color: $fill-color;
            //     z-index: 3;
            // }
            &.done .step-bar-right {
                background-color:$fill-color;
                border-color: $fill-color;
                z-index: 3;
                width: calc(100% - 2rem);
            }
        }
}

@media all and (max-width: 1600px) {
    .stepper-div .step .step-title {
        font-size: 0.98rem;
    }
}

@media all and (max-width: 1440px) {
    .stepper-div .step .step-title {
        font-size: 0.74rem;
    }
}

@media all and (max-width: 1024px) {
    .stepper-div .step .step-title {
        font-size: 0.72rem;
    }    
    .home-cooptation-quote-img {
        //max-width: 480px;
    }
}

@media (max-width: 900px) {

}
    
@media screen and (max-width: 768px){
    .home-div-to-hide{
        visibility: hidden;
    }
    .home-banner{
        // background-color: #ffffff!important;
        background-image:none !important;
        padding: 0;
    }
    .home-banner-title-div{
        background-image: url("./../assets/img/ECRAN_ANNONCE_BANNIERE.jpg")  ;
        background-size: cover;
        height: 100%;
        width: 100%;
        overflow: hidden;
        background-position-x: center;
        background-position-y: center;
        place-content: center;
        text-align: center;
        top:0px;
       
    }
    .border-opportunity-search {
        background-color: white;
        margin-top: -190px;
    }
    .home-banner-title{
        font-weight: bold;
        color: #ffffff;
       margin-top: 115px;
        font-size: 23px;
        margin-bottom: 70px;
        padding: 85px;
        white-space: nowrap;
    }
    .home-banner-btn-2{
        margin-top: 40px;
    }

    .home-stepper-title {
        font-weight: bold;
        margin-top: 20px;
        text-align: center;
    }
    .home-title-div{
        text-align: -webkit-center;
        justify-content: center;
        margin: 15px 0;
    }
    .home-title{
        font-size: 1.3rem;
    }
    .home-stepper-NB{
        margin-left: 2.5rem;
    }
   
    .stepper-div {
        margin-top: 0;
        margin-bottom: 0;
        .step {
            display:table-cell;
            position:relative;
            padding:1.5rem;
            z-index: 2;
            width: 120%;
            flex-direction: row;
            justify-content: flex-start;
            margin-top: 3px;
            background-color: cream;
            display: flex;
            flex-wrap: wrap;
            height:90px;
          }
          
          .stepper {
            position: relative;
            left:-1.6%;
          /*   visibility: visible; */
            align-self: center;
          }
          
          
          /* regular step */
          .step-circle {
            background-color: white;
            border: 3px solid #00c072;
            border-radius: 100%;
            width: 2.5rem!important;    /* +6 for border */
            height: 2.5rem!important;
            display: inline-block!important;
            text-align: center;
            line-height:2.1rem!important;
            font-size: 1rem!important;
            font-weight: 700;
            z-index:2;
            padding-bottom: 5px!important;
            color:#00c072;
            font-weight:600!important;
            max-width:3rem!important;
          }
        
          
          .step-bar-right {
            top: 30px !important;
            height: 250% !important;
            margin-left: 0.85rem !important;
            position: absolute !important;
            border-left: 3px solid #00c072 !important;
            left:13% !important;
          }

          .last-child {
            border-left: 3px solid #f5f5f5;
            z-index: -1; /* behind the circle to completely hide */
            //margin-top: -20px;
          }

          .step-title{
            margin-top: 0.2rem !important;
            margin-left: 4%;
            text-align: left!important;
            width: 70%;
            font-size: 0.75rem !important;
          }
    }

    .home-cooptation-opportunity-div {
        width: 100%;
        margin: 0;
    }

    .home-cooptation-opportunity{
        text-align: -webkit-center;
    }
    .home-cooptation-opportunity-article-title{
        text-align: left;
        padding-left: 4%;
    }
    .home-cooptation-opportunity-article-description {
        text-align: left;
        padding-left: 4%;
    }
    
    .home-articles {
        margin: 0;
        // width: 120%;
    }

    .article-div {
        margin-top:40px;
    }
    .home-cooptation-quote-img {
        width: 100%;
        height: auto;
    }
    .home-cooptation-end-div {
        justify-content: center;
    }
    .home-cooptation-quote-div-2 {
        margin-top: 70px;
        margin-bottom: unset;
    }
    .home-cooptation-quote{
        margin-top: 10px;
        line-height: 1.25;
    }
    .home-cooptation-end-text-div{
        margin-bottom: 30px;
    }
}

	

