@keyframes ldio-bkfy3ogx47k {
   0% { transform: translate(6px,40px) scale(0); }
  25% { transform: translate(6px,40px) scale(0); }
  50% { transform: translate(6px,40px) scale(1); }
  75% { transform: translate(40px,40px) scale(1); }
 100% { transform: translate(74px,40px) scale(1); }
}

@keyframes ldio-bkfy3ogx47k-r {
   0% { transform: translate(74px,40px) scale(1); }
 100% { transform: translate(74px,40px) scale(0); }
}

@keyframes ldio-bkfy3ogx47k-c {
   0% { background: #009c49 }
  25% { background: #595959 }
  50% { background: #211d15 }
  75% { background: #595959 }
 100% { background: #009c49 }
}

.ldio-bkfy3ogx47k div {
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  transform: translate(40px,40px) scale(1);
  background: #000000;
  animation: ldio-bkfy3ogx47k 1s infinite cubic-bezier(0,0.5,0.5,1);
}
.ldio-bkfy3ogx47k div:nth-child(1) {
  background: #595959;
  transform: translate(74px,40px) scale(1);
  animation: ldio-bkfy3ogx47k-r 0.25s infinite cubic-bezier(0,0.5,0.5,1), ldio-bkfy3ogx47k-c 1s infinite step-start;
}.ldio-bkfy3ogx47k div:nth-child(2) {
  animation-delay: -0.25s;
  background: #009c49;
}.ldio-bkfy3ogx47k div:nth-child(3) {
  animation-delay: -0.5s;
  background: #595959;
}.ldio-bkfy3ogx47k div:nth-child(4) {
  animation-delay: -0.75s;
  background: #211d15;
}.ldio-bkfy3ogx47k div:nth-child(5) {
  animation-delay: -1s;
  background: #595959;
}
.loadingio-spinner-ellipsis-7hoz70l40sk {
    width: 64px;
    height: 40px;
    display: inline-flex;
    overflow: hidden;
    background: transparent;

    margin: -15px;
    margin-left: 15px;  
}
.ldio-bkfy3ogx47k {
    width: 100%;
    height: 100%;
    position: relative;
    transform: translateZ(0) scale(0.64);
    backface-visibility: hidden;
    transform-origin: 0 0; /* see note above */
}

.ldio-bkfy3ogx47k div { box-sizing: content-box; }

.divLoaderMini {
    display: inline-flex;
    z-index: 9999;
    background: transparent;
}