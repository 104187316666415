.container{
  padding-top: 4%;
  max-width: 100%;
  min-height: calc(100vh - 167px);
}
@media (max-width: 1600px) {
  .container {
    max-width: 100%;
    padding-top: 4%;
    //min-height:82.4%;
    min-height: calc(100vh - 168px);
  }
}

@media (max-width: 1440px) {
  .container {
    padding-top: 4%;
    max-width: 100%;
    //min-height:82.4%;
    min-height: calc(100vh - 168px);
  }
}

@media (max-width: 900px) {
  .container{
    padding-top: 0%;
    max-width: 100%;
  }
}
