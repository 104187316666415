/*Desktop View*/
.header {
  display: flex;
  //background: linear-gradient(#3916fc, #88e9ff);
  background: #ffffff;
  justify-content: space-between;
  align-items: center;
  padding: 0px 40px;
  height: 80px;
  position: fixed;
  width:100%;
  z-index:+3;
  top:0;
  box-shadow: 1px 1px 10px #f3f2f2;
}

.logo-nav {
  display: flex;
  align-items: center;
  // justify-content: space-evenly;
  width: 37%;
  margin-left:5%;
}

.logo-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo {
  height: 60px;
}

.app-title {
  width: 14%;
  text-align: center;
  cursor: pointer;
  // margin-left: 7%;
  .nav-user{
    display: none;
  }
  span {
    font-weight: bold;
    text-transform: uppercase;
    font-size: x-large;
  }
}

.nav-user {
  padding-left: 15px;
  display: grid;
  grid-template-columns: repeat(5, auto);
  grid-gap: 14px;
  list-style-type: none;
  padding-top: 3px;

  .pipe {
    border-left: 1px solid #707070;
    width: 1px;
  }
}

.nav-options {
  padding-left: 25px;
  display: grid;
  grid-template-columns: repeat(5, auto);
  grid-gap: 14px;
  list-style-type: none;
  width: 43%;
  justify-content: inherit;

  .option {
    line-height: 2;
    font-size: small;
    font-weight: 600;
  }
}
.active {
  color: #009c49;
}

.mobile-option {
  display: none;
}

.option :hover {
  color: #009c49;
}

.signin-up {
  display: flex;
  padding: 0px 5px;
  list-style-type: none;
}

.sign-in {
  padding-right: 50px;
  align-self: center;
}

.sign-in :hover {
  color: #009c49;
}

.signup-btn {
  padding: 10px 10px;
  height: 2.4rem;
  border-radius: 3px;
  background: rgb(222, 9, 241);
  color: white;
  cursor: pointer;
  align-items: center;
}

.mobile-menu {
  display: none;
}
.hidden_mobile{
  visibility: visible!important;
}
.show_mobile{
  visibility: hidden!important;
}

@media (max-width: 962px) {
  /*Mobile View */
  .header {
    padding: 0px 10px;
    background: transparent;
    box-shadow: 0px 0px 0px #f3f2f2;
  }
  .header.onfocus{
    background: #2d2927;
    .app-title .header-sitename{
      visibility: hidden;
      display: none;
    }
    .app-title .nav-user {
      display: block;
      visibility: visible;
      padding-left: unset;
      span {
        font-size: small;
        color: white;
        text-transform: none;
      }
    }
  }
  .logo {
    width: 100px;
    height: auto;
  }
  .logo-nav {
    width: 45px;
    margin-left: unset;
  }
  .app-title{
    visibility: hidden;
    width: auto;
  }
  .logo-container{
    visibility: hidden;
  }

  .nav-options {
    display: flex;
    width: 100%;
    /* height: 350px; */
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 0;
    transition: all 0.5s ease;
    flex-direction: column;
    list-style-type: none;
    grid-gap: 0px;
  }

  .nav-options.active {
    // background: #87e7ff;
    background: #2d2927;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
    align-content: center;
    padding-left: 0px;
  }

  .mobile-menu {
    width: 45px;
    text-align: right;
    display: block;
    
  }
  .mobile-menu.opened{
    color:#dddcdc;
  }

  .menu-icon {
    width: 45px;
    height: 45px;
  }

  .option {
    display: flex;
    align-items: center;
    justify-content: left;
    height: 5vw;
    padding: 30px 30px;
    line-height: unset;
    color:#dddcdc!important;
  }
  a{
    color:#dddcdc!important;
  }
  .active {
    color: #009c49!important;
  }
  
  
  .sign-up {
    background: rgb(222, 9, 241);
    border-radius: 3px;
    color: white;
    padding: 20px 0px;
    width: 80%;
    align-self: center;
    border-radius: 3px;
  }

  .signin-up {
    display: none;
  }
  .hidden_mobile{
    visibility: hidden!important;
  }
  .show_mobile{
    visibility: visible!important;
  }
  .mobile-deconnection{
    color:#dddcdc!important;
    font-size: small;
    font-weight: 600;
  }
  .mobile-deconnection:hover{
    color:#dddcdc!important;
    text-decoration: underline;
  }
}

/*Popovers*/
.popover__title_flag, .popover__title_gear {
  font-size: 24px;
  line-height: 36px;
  text-decoration: none;
  color: rgb(228, 68, 68);
}

.popover__content_flag, .popover__content_gear {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  // transform: translate(0, 10px);
  background-color: #bfbfbf;
  // padding: 0.1rem;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.26);
  border-radius: 5px;
  margin-top: 1.5%;
  align-content: center ;
  // padding-top: 1rem;
  font-size: smaller;
  height:20px;
  padding-left: 4px;
  padding-right: 4px;
}
.popover__content_flag {
  right:12%;
}
.popover__content_gear {
  //right:12%;
}
//.popover__content_flag:before {
  // position: absolute;
  // z-index: -1;
  // content: "";
  // right: calc(50% - 10px);
  // top: -8px;
  // border-style: solid;
  // border-width: 0 10px 10px 10px;
  // border-color: transparent transparent #bfbfbf transparent;
  // transition-duration: 0.3s;
  // transition-property: transform;
//}
.popover__wrapper_flag:hover .popover__content_flag, .popover__wrapper_gear:hover .popover__content_gear {
  z-index: 10;
  opacity: 1;
  visibility: visible;
  transform: translate(0, -20px);
  transition: all 0.5s cubic-bezier(0.75, -0.02, 0.2, 0.17);
}
.popover__message_flag, .popover__message_gear {
  text-align: center;
}

/*Logout*/
.popover__title_logout {
  // font-size: 24px;
  // line-height: 36px;
  text-decoration: none;
  color: rgb(228, 68, 68);

}

.popover__content_logout {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  // transform: translate(0, 10px);
  background-color: #bfbfbf;
  // padding: 0.1rem;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
  border-radius: 5px;
  margin-top: 1.5%;
  align-content: center ;
  // padding-top: 1rem;
  right:7%;
  font-size: smaller;
  height:20px;
  padding-left: 4px;
  padding-right: 4px;
}
//.popover__content_logout:before {
  // position: absolute;
  // z-index: -1;
  // content: "";
  // right: calc(50% - 10px);
  // top: -8px;
  // border-style: solid;
  // border-width: 0 10px 10px 10px;
  // border-color: transparent transparent #bfbfbf transparent;
  // transition-duration: 0.3s;
  // transition-property: transform;
//}
.popover__wrapper_logout:hover .popover__content_logout {
  z-index: 10;
  opacity: 1;
  visibility: visible;
  transform: translate(0, -20px);
  transition: all 0.5s cubic-bezier(0.75, -0.02, 0.2, 0.97);
}
.popover__message_logout {
  text-align: center;
}
