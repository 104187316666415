.footer{
    background-color: #2d2927;
    color: #ffffff;
    // position: fixed;
    width:100%;
    z-index:+3;
    bottom: 0;
    left: 0;
    margin-bottom: 0px;
    min-height:17.6%;
 

}
.footer-title{
    font-weight: 600;
}
.footer-div-part{
    margin-top:30px;
    margin-bottom:20px;
}
.footer-line{
    margin-top: -25px;
}

.footer-details{
    font-size: 11px; 
}

.footer-line-1-fr{
    //width:35%;
    width: 4.2rem;
    height: 4%;
}

.footer-line-1-en{
    //width:15%;
    width: 3.6rem;
    height: 4%;
}

.footer-line-2-fr{
    //width:27%;
    width: 4.2rem;
    height: 4%;
}

.footer-line-2-en{
    //width:20%;
    width: 3.6rem;
    height: 4%;
}

.footer-line-3-fr{
    //width:18%;
    width: 4.2rem;
    height: 4%;
}

.footer-line-3-en{
    //width:18%;
    width: 3.6rem;
    height: 4%;
}

.footer-details {
    img {
        -webkit-filter: invert(100%) hue-rotate(180deg);
        filter: invert(100%) hue-rotate(180deg);  
    }
}

.footer-details a{
    color: #ffffff;
}
.footer-div-part-2{
    margin-top: 4px;
}
.footer-legal-mentions{
    text-align: center;
}
.footer-contact-details{
    margin-top: -10px;
}
.footer-p-details{
    margin-top: -10px;
}
.footer-p-details-icon{
    margin-top: -15px;
}

.sticky {
    position:absolute;
    left:0;
    bottom:0;
    right:0;
}

@media screen and (max-width: 768px){
    .footer{
        &>div{
            padding: 0;
            margin: 0;
        }
        .footer-div-part {
            padding: 1rem 1rem;
            margin: 0;
        }
        .footer-line {
            width: 75%;
        }
    }
}
