@import url('https://fonts.googleapis.com/css?family=Poppins:300,400,600,700,800');

@import "~bootstrap/scss/bootstrap";
@import 'variables';

@import 'header';
@import 'container';

body {
  font-family: Poppins;
  font-size: $font-size;
  background-color: #f5f5f5;
}

.dark {
  filter: invert(100%) hue-rotate(180deg); 
  img.ignore-dark {
    filter: invert(100%) hue-rotate(180deg);
  } 
  video.ignore-dark {
    filter: invert(100%) hue-rotate(180deg);
  }
  iframe.ignore-dark {
    filter: invert(100%) hue-rotate(180deg);
  }  
}

.header {
  ol, ul, dl {
    margin: unset;
  }
}

a {
  text-decoration: none;
  color: black;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.alert {
  position: relative;
  padding: $alert-padding-y $alert-padding-x;
  margin-bottom: $alert-margin-bottom;
  border: $alert-border-width solid transparent;
  @include border-radius($alert-border-radius);

  &.custom-alert {
    position: absolute;
    width: -moz-available;   
    width: -webkit-fill-available;
    width: fill-available;
    margin: 1rem;
  }
}

.error {
  text-align: center;
  font-size: 5rem;
  padding: 2rem 5rem;
}

.noselect {
  -webkit-touch-callout: none; 
    -webkit-user-select: none; 
     -khtml-user-select: none; 
       -moz-user-select: none; 
        -ms-user-select: none; 
            user-select: none; 
}

.align-center {
  justify-content: center;
  text-align: center;
}

.vertical-center {
  // margin: 0;
  // position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.no-cooptation-found {
  font-size: 1.6rem;
}

.errordescription{
  font-size: 35px;
  font-weight: bold;
  font-variant: small-caps;
  line-height: 1.2;
}

.MuiAlert-standardError {
  color: rgb(255, 255, 255) !important;
  background-color: rgb(82, 79, 79) !important;
}

.popover__title_flag {
  border-radius: 4px;
}

.MuiDialogActions-root {
  justify-content: center !important;
}

.MuiDialog-paperWidthSm {
  width: 75%;
}

.MuiDialog-paperWidthSm {
  max-width: 490px !important;
}

.MuiDialog-paper {
  text-align: center;
}

.MuiTypography-h6 {
  font-family: inherit !important;
  font-size: 1.5rem !important;
}

.MuiTypography-body1 {
  font-family: inherit !important;
  font-size: 1.25rem !important;
}

.btn {
  border-radius: 2rem;
  min-width: 100px;
  font-weight: 900;

  &.btn-dark {
    background-color: $blackAstek;
    border-color: transparent;
    color: #fff;

    &:hover {
      background-color: white;
      border-color: transparent;
      color: $blackAstek;
    }
  }
}

@media screen and (max-width: 768px){
  .container {
    min-height: unset;
  }
  
  .p-xs-0 {
    padding: 0 !important;
  }

  .error {
    text-align: center;
    font-size: 3rem;
    padding: 5rem 0;
  }

  .errordescription{
    font-size: 1.5rem;
    font-weight: bold;
    font-variant: small-caps;
    line-height: 1.2;
    padding: 2rem;
  }  

  .MuiTypography-body1 {
    font-size: 1rem !important;
  }
}

.rcs-custom-scroll{min-height:0;min-width:0}.rcs-custom-scroll .rcs-outer-container{overflow:hidden}.rcs-custom-scroll .rcs-outer-container .rcs-positioning{position:relative}.rcs-custom-scroll .rcs-outer-container:hover .rcs-custom-scrollbar{opacity:1;transition-duration:.2s}.rcs-custom-scroll .rcs-inner-container{overflow-x:hidden;overflow-y:scroll;-webkit-overflow-scrolling:touch}.rcs-custom-scroll .rcs-inner-container:after{content:"";position:absolute;top:0;right:0;left:0;height:0;background-image:linear-gradient(to bottom, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.05) 60%, rgba(0, 0, 0, 0) 100%);pointer-events:none;transition:height .1s ease-in;will-change:height}.rcs-custom-scroll .rcs-inner-container.rcs-content-scrolled:after{height:5px;transition:height .15s ease-out}.rcs-custom-scroll.rcs-scroll-handle-dragged .rcs-inner-container{user-select:none}.rcs-custom-scroll .rcs-custom-scrollbar{position:absolute;height:100%;width:6px;right:3px;opacity:0;z-index:1;transition:opacity .4s ease-out;padding:6px 0;box-sizing:border-box;will-change:opacity;pointer-events:none}.rcs-custom-scroll .rcs-custom-scrollbar.rcs-custom-scrollbar-rtl{right:auto;left:3px}.rcs-custom-scroll.rcs-scroll-handle-dragged .rcs-custom-scrollbar{opacity:1}.rcs-custom-scroll .rcs-custom-scroll-handle{position:absolute;width:100%;top:0}.rcs-custom-scroll .rcs-inner-handle{height:calc(100% - 12px);margin-top:6px;background-color:rgba(78,183,245,.7);border-radius:3px}

.ScrollbarsCustom-Track {
  &.ScrollbarsCustom-TrackY {
    border-radius: 0 !important;
    background: #f5f5f5 !important;
    top: 20px !important;
  }
}

.ScrollbarsCustom-Thumb {
  &.ScrollbarsCustom-ThumbY {
    border-radius: 0 !important;
  }
}

@media screen and (max-width: 768px){
  .ScrollbarsCustom-Wrapper {
    inset: 0px 0px 0px 0px !important;
  }

  .ScrollbarsCustom-Track {
    &.ScrollbarsCustom-TrackY {
      width: 0 !important;
    }
  }
}

//Loader - 2
@keyframes ldio-hp4uicrw139 {
   0% { transform: translate(7.199999999999999px,48px) scale(0); }
  25% { transform: translate(7.199999999999999px,48px) scale(0); }
  50% { transform: translate(7.199999999999999px,48px) scale(1); }
  75% { transform: translate(48px,48px) scale(1); }
 100% { transform: translate(88.8px,48px) scale(1); }
}
@keyframes ldio-hp4uicrw139-r {
   0% { transform: translate(88.8px,48px) scale(1); }
 100% { transform: translate(88.8px,48px) scale(0); }
}
@keyframes ldio-hp4uicrw139-c {
   0% { background: #009c49 }
  25% { background: #595959 }
  50% { background: #211d15 }
  75% { background: #595959 }
 100% { background: #009c49 }
}
.ldio-hp4uicrw139 div {
  position: absolute;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  transform: translate(48px,48px) scale(1);
  background: #009c49;
  animation: ldio-hp4uicrw139 1.6666666666666667s infinite cubic-bezier(0,0.5,0.5,1);
}
.ldio-hp4uicrw139 div:nth-child(1) {
  background: #595959;
  transform: translate(88.8px,48px) scale(1);
  animation: ldio-hp4uicrw139-r 0.4166666666666667s infinite cubic-bezier(0,0.5,0.5,1), ldio-hp4uicrw139-c 1.6666666666666667s infinite step-start;
}.ldio-hp4uicrw139 div:nth-child(2) {
  animation-delay: -0.4166666666666667s;
  background: #009c49;
}.ldio-hp4uicrw139 div:nth-child(3) {
  animation-delay: -0.8333333333333334s;
  background: #595959;
}.ldio-hp4uicrw139 div:nth-child(4) {
  animation-delay: -1.25s;
  background: #211d15;
}.ldio-hp4uicrw139 div:nth-child(5) {
  animation-delay: -1.6666666666666667s;
  background: #595959;
}
.loadingio-spinner-ellipsis-jjawpuv739m {
  // width: 120px;
  // height: 120px;
  // display: inline-block;
  // overflow: hidden;
  background: transparent;
}
.ldio-hp4uicrw139 {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
  background: transparent;  
}
.ldio-hp4uicrw139 div { box-sizing: content-box; }

.link{
  cursor: pointer;
}

@import 'loader.spinner.min.scss'